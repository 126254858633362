import React from "react";
import { graphql } from "gatsby";

import Grid from "@mui/material/Grid";

import { 
    About,
    Layout,
    Sidebar
} from "../components";

const AboutUs = ({ data }) => {

	const latestChallenge = data?.latestChallenge?.challenges[0];

	return (
		<Layout 
            title="Our Story"
        >
			<Grid
				container
				spacing={4}
				sx={
					{
						py: {
                            lg: 2,
                            xs: 2,
                        }
					}
				}
			>
				<Grid
					item
					xs={true}
				>
					<About latestChallenge={latestChallenge} />
				</Grid>
				<Grid
					item
                    md="auto"
					xs={12}
				>
					<Sidebar />
				</Grid>
			</Grid>
		</Layout>
	)
}
export default AboutUs;

export const pageQuery = graphql`
    query {
        latestChallenge: allWpChallenge(
            sort: { fields: [date], order: DESC }
            limit: 1
        ) {
            challenges: nodes {
                id
                databaseId
                title
                slug
                startDatetime
                endDatetime
                rewardImage {
                    altText
                    localFile {
                        icon: childImageSharp {
                            gatsbyImageData(height: 32)
                        }
                        small: childImageSharp {
                            gatsbyImageData(height: 60)
                        }
                        medium: childImageSharp {
                            gatsbyImageData(width: 200)
                        }
                    }
                }
                rewardName
                rewardSpots
            }
        }
    }
`